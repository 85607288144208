





















import Component, { mixins } from "vue-class-component";
import { TabRoute } from "@/mixins/TabRoute";

@Component({})
export default class Settings extends mixins(TabRoute) {
  private regularRoutes: Array<string>;
  constructor() {
    super();

    this.regularRoutes = [
      "/settings",
      "/settings/account",
      "/settings/configuration"
    ];
    if (this.regularRoutes.includes(this.$route.path)) {
      this.activeTab = this.$route.path;
    } else {
      this.activeTab = "/settings/account";
    }
  }
}
