import Vue from "vue";
import Component from "vue-class-component";
import { Watch } from "vue-property-decorator";

@Component
export class TabRoute extends Vue {
  public activeTab: string;

  constructor() {
    super();
    this.activeTab = this.$route.path;
  }

  @Watch("activeTab")
  private changeRouter() {
    if (this.$route.path !== this.activeTab) {
      this.$router.push(this.activeTab);
    }
  }
}
